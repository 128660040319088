/************
 * Developed by : Shiva Software Solutions
 * Date : 28-04-2023
 * Descriptions : Appointment api calling
 ************/
import {
  APPOINTMENT_COMPLETED_DATA,
  APPOINTMENT_UPCOMING_DATA,
  FETCH_START,
  FETCH_SUCCESS,
  APPOINTMENT_INSERTED_SUCCESS,
  APPOINTMENT_BOOKED_TIMING,
} from 'shared/constants/ActionTypes';
import jwtAxios from '../../@crema/services/auth/jwt-auth/jwt-api';
import {fetchError, onWriteCloudWatchLog} from './Common';
//AWS cloud watch name
var streamname = 'Redux_Actions';
var filename = 'Appointment.js';

export const onGetAppointments = ({mappedDoctor, doctorId, process}) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    const body = {mappedDoctor, doctorId, process};
    jwtAxios
      .post('v1/docMgmt/getPatientAppointmentDetailsJwt', body)
      .then((data) => {
        try {
          body['jwtToken'] = data.data.body['token'];
          jwtAxios
            .post('v1/docMgmt/getPatientAppointmentDetails', body)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: APPOINTMENT_COMPLETED_DATA,
                  payload: data.data.body.completedData,
                });

                dispatch({
                  type: APPOINTMENT_UPCOMING_DATA,
                  payload: data.data.body.upcomingData,
                });
              }
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAppointments',
              '0',
              err,
            ),
          );
        }
      });
  };
};

export const onSaveAppointments = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios.post('v1/docMgmt/saveNewAppointmentsjwt', body).then((data) => {
      try {
        body['jwtToken'] = data.data.body['token'];
        jwtAxios.post('v1/docMgmt/saveNewAppointments', body).then((data) => {
          if (data.data.status === 200) {
            dispatch({type: FETCH_SUCCESS});
            dispatch({
              type: APPOINTMENT_INSERTED_SUCCESS,
              payload: data.data.body,
            });
          }
        });
      } catch (err) {
        dispatch(fetchError(err.response.message));
        dispatch(
          onWriteCloudWatchLog(
            streamname,
            filename,
            'onGetAppointments',
            '0',
            err,
          ),
        );
      }
    });
  };
};

export const getAvailableTimeForAppointment = (body) => {
  return (dispatch) => {
    dispatch({type: FETCH_START});
    jwtAxios
      .post('v1/docMgmt/getAvailableAppointmentTimeJWT', body)
      .then((data) => {
        try {
          body['jwtToken'] = data.data.body['token'];
          jwtAxios
            .post('v1/docMgmt/getAvailableAppointmentTime', body)
            .then((data) => {
              if (data.data.status === 200) {
                dispatch({type: FETCH_SUCCESS});
                dispatch({
                  type: APPOINTMENT_BOOKED_TIMING,
                  payload: data.data.body.bookingTime,
                });
              }
            });
        } catch (err) {
          dispatch(fetchError(err.response.message));
          dispatch(
            onWriteCloudWatchLog(
              streamname,
              filename,
              'onGetAppointments',
              '0',
              err,
            ),
          );
        }
      });
  };
};
